import BagsTemplate from '@/modules/questionnaire/components/steps/photo-analysis/skin-appearance/bags/BagsTemplate';

import { EYE_BAGS_TYPES } from '@/modules/questionnaire/api/constants';

export default {
  title: 'PhotoAnalysisSteps/SkinAppearance/Bags/BagsTemplate',
  component: BagsTemplate
};

const createStory = props => () => ({
  components: { BagsTemplate },
  storyProps: props,
  wrapStyles: {
    display: 'flex',
    justifyContent: 'center'
  },
  template: `<div :style='$options.wrapStyles'>
    <bags-template v-bind="$options.storyProps" />
  </div>`
});

export const required = createStory({
  eyeBags: 'yes'
});

export const all = createStory({
  eyeBags: 'yes',
  eyeBagsType: EYE_BAGS_TYPES.FLUID,
  isEyeBagsTypeVisible: true
});

export const aiSelected = createStory({
  eyeBags: 'yes',
  eyeBagsAiSelected: 'yes',
  eyeBagsType: EYE_BAGS_TYPES.FLUID,
  isEyeBagsTypeVisible: true
});
